<template>
    <div  @keydown.escape="$emit('close-selection')"  class="relative inline-block text-left">
        <div>
            <span class="rounded-md shadow-sm">
                <button @click="$emit('toggle-selection')" type="button" class="inline-flex outline-none active:outline-none rounded-l-md justify-center w-full bg-gray-100 px-4 py-2 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
<!--          <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">-->
<!--                                <path fill-rule="evenodd" d="M3 5a2 2 0 012-2h6a2 2 0 012 2H5v8a2 2 0 01-2-2V5zm6 2a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2V9a2 2 0 00-2-2H9z" clip-rule="evenodd"/>-->
<!--                            </svg>-->
                    <img :src="avatarUrl(owner.avatar_url)"  class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500">
                    {{ owner.username }}
                    <svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                    </svg>
                </button>
            </span>
        </div>
        <transition
                enter-class="transform opacity-0 scale-95"
                enter-active-class="transition ease-out duration-100"
                enter-to-class="transform opacity-100 scale-100"
                leave-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-to-class="transform opacity-0 scale-95"
        >
            <div v-if="open" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
                <div class="rounded-md bg-white shadow-xs">
                    <div class="">
                        <div @click="ownerSelected(getUser)" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 cursor-pointer">
                            <img :src="avatarUrl(getUser.avatar_url)"  class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500">
                            {{ getUser.username }}
                        </div>
                        <div class="border-t border-gray-100"></div>
                        <div v-for="(org, index) in orgs" :key="index" @click="ownerSelected(org)" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 cursor-pointer">
                            <img :src="avatarUrl(org.avatar_url)"  class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500">
                            {{ org.username }}
                        </div>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "Selection",
        props:['open', 'orgs', 'owner'],
        data() {
            return {
                selectedOwner: this.owner
            }
        },
        computed: {
            ...mapGetters(['getUsername', 'getToken', 'getUser']),

        },
        methods: {
            avatarUrl(url) {
                return url.replace(":3000", "")
            },
            ownerSelected(owner){
                this.$emit('owner-selected', owner)
                this.$emit('close-selection')
            },
        }
    }
</script>

<style scoped>

</style>
