<template>
    <div>
        <div class="min-h-screen bg-jiruto-main flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-6">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Verify your email
                        </h3>
                        <div class="mt-2 max-w-xl text-sm  leading-5 text-gray-500">
                            <p>
                                A verification email has been sent to you.
                            </p>
                            <br>
                            <p>
                                Verifying your account helps us provide better security and service in our platform. It
                                prevents people from using email that don't belong to them.
                            </p>
                            <br>
                            <p>
                                If you didn't receive a verification email from us please press the button bellow and
                                we'll resend it.
                            </p>
                        </div>
                        <div class="mt-5 flex justify-between">
                            <button type="button" @click="sendVerificationEmail"
                                    class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Resend verification email
                            </button>
                            <button type="button" @click="refreshPage"
                                    class="inline-flex mx-2 items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Refresh Page
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal :open="open" v-on:close-modal="open=false">
            <template v-slot:header>
                Verification email sent!
            </template>
            <template v-slot:text>
                Please check your email. If the verification email still wasn't sent, please check your spam folder.
            </template>
        </modal>
    </div>

</template>

<script>
    import Modal from  './SuccessModal'
    import * as fb from '../firebaseConfig'
    export default {
        name: "Verify",
        components: {
            modal: Modal
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            refreshPage(){
                let loader = this.$loading.show({
                    onCancel: this.onCancel,
                });
                fb.auth.currentUser.reload().then(() => {
                    location.reload()
                    loader.hide()
                })
            },
            sendVerificationEmail() {
                // this.open = true
                this.$store.dispatch('sendVerificationEmail').then(this.open = true).catch(err => {
                    console.error("error sending verification Email", err)
                })
            },
        }
    }

</script>

<style scoped>

</style>
