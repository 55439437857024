<template>
   <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 my-5">
       <div class="flex flex-col xl:flex-row justify-evenly">
           <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 order-2 xl:order-1 mt-5 xl:mr-5">
               <form @submit.prevent="newCommunity">
                   <div>
                       <div>
                           <div>
                               <h3 class="text-lg leading-6 font-medium text-gray-900">
                                   New Learning Community
                               </h3>
                               <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                   A learning community contains everything needed to learn a subject.
                               </p>
                           </div>

                           <div class="mt-6 sm:mt-5">
                               <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                   <label for="username" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                       Owner and community name
                                   </label>
                                   <div class="mt-1 sm:mt-0 sm:col-span-2">
                                       <div class="max-w-lg flex items-center rounded-md shadow-sm">
                                       <span class="inline-flex items-center  rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                           <selection :owner="selectedOwner" :orgs="orgs" v-on:close-selection="selectionOpen = false"  v-on:toggle-selection="toggleSelect" v-on:owner-selected="ownerSelected"  :open="selectionOpen" class="z-10"></selection>
                                       </span>
                                           <input type="text" v-model.trim="communityName" @blur="communityNameBlurred" @focus="communityNameFocused" @input="checkNameAvailability" id="username"
                                                  class="flex-1 z-10 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Enter community name"
                                                  :class="{'placeholder-red-500': $v.communityName.$error, 'border-red-500': $v.communityName.$error, 'border-green-500 border-2': isNameAvailable }"/>
                                       </div>

                                       <div>
                                           <p v-if="nameAvailable === false" class="text-sm text-red-500">Unavailable name</p>
                                           <p v-if="isNameAvailable" class="text-sm text-green-500">Name Available</p>

                                       </div>

                                       <div>
                                           <p v-if="!$v.communityName.allowedCharacters" class="text-red-800 text-sm">Invalid Characters: Allowed Characters are 0-9, a-Z, space, "-", and "_"</p>
                                       </div>
                                   </div>
                               </div>

                               <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                   <label for="about" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                       Description
                                   </label>
                                   <div class="mt-1 sm:mt-0 sm:col-span-2">
                                       <div class="max-w-lg flex rounded-md shadow-sm">
                                           <textarea rows="7" v-model.trim="description" @blur="$v.description.$touch()" id="about" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Describe your community" :class="{'placeholder-red-500': $v.description.$error, 'border-red-500': $v.description.$error }"></textarea>
                                       </div>
                                       <p class="mt-2 text-sm text-gray-500">What is this community aiming to achieve? What will those who follow it learn?</p>
                                   </div>
                               </div>

                               <!--                           <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">-->
                               <!--                               <label for="about" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">-->
                               <!--                                   Exposure-->
                               <!--                               </label>-->
                               <!--                               <fieldset>-->
                               <!--                                   <div>-->
                               <!--                                       <div class=" flex items-center">-->
                               <!--                                           <input type="radio" id="public" :value="false" v-model="isPrivate" name="form-input privacy" class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"/>-->
                               <!--                                           <label for="public" class="ml-3">-->
                               <!--                                               <span class="block text-sm leading-5 font-medium text-gray-700">Public</span>-->
                               <!--                                           </label>-->
                               <!--                                       </div>-->
                               <!--                                       <div v-if="isPremium === true" class="mt-4 flex items-center" >-->
                               <!--                                           <input id="privatePremium" :value="true" name="form-input privacy" type="radio" v-model="isPrivate" class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"/>-->
                               <!--                                           <label for="privatePremium" class="ml-3">-->
                               <!--                                               <span class="block text-sm leading-5 font-medium text-gray-700">Private</span>-->
                               <!--                                           </label>-->

                               <!--                                       </div>-->
                               <!--                                       <div v-else class="mt-4 flex items-center" >-->
                               <!--                                           <input disabled  id="private" :value="true" name="form-input privacy" type="radio" v-model="isPrivate" class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"/>-->
                               <!--                                           <label for="private" class="ml-3" >-->
                               <!--                                               <span class="block text-sm leading-5 font-medium text-gray-400">Private</span>-->
                               <!--                                           </label>-->

                               <!--                                           <div class="ml-10 flex-shrink-0">-->
                               <!--                                                <span class="inline-flex rounded-md shadow-sm">-->
                               <!--                                                    <router-link tag="button" :to="{name: 'upgrade'}" class="relative h-8 inline-flex items-center text-center px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-jiruto-zotDarker hover:bg-jiruto-zotSecondary focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">-->
                               <!--                                                        Upgrade to pro-->
                               <!--                                                    </router-link>-->
                               <!--                                                </span>-->
                               <!--                                           </div>-->
                               <!--                                       </div>-->
                               <!--                                   </div>-->
                               <!--                               </fieldset>-->
                               <!--                           </div>-->
                           </div>
                       </div>
                   </div>
                   <div class="mt-8 border-t border-gray-200 pt-5">

                       <div class="flex" :class="{'justify-between': proError, 'justify-end': !proError}">
                           <p v-if="proError" class="text-jiruto-flatRed">* This actions requires a Pro subscription</p>
                           <span class="ml-3 inline-flex rounded-md shadow-sm">
                           <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                               Create Community
                           </button>
                       </span>
                       </div>
                   </div>
               </form>
               <!--           {{ $v }}-->
           </div>

           <div class="order-1 xl:order-2 mt-5">
               <div class="border bg-purple-100 border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200s">
                   <div class="p-6">
                       <h2 class="text-lg leading-6 font-medium text-gray-900">Before you create</h2>
                       <p class="mt-4 text-sm leading-5 text-gray-500">Make sure you're following our general guidelines</p>
                       <!--                    <p class="mt-8">-->
                       <!--                        <span class="text-4xl leading-10 font-extrabold text-gray-900">$24</span>-->
                       <!--                        <span class="text-base leading-6 font-medium text-gray-500">/mo</span>-->
                       <!--                    </p>-->
                   </div>
                   <div class="pt-6 pb-8 px-6">
                       <h3 class="text-xs leading-4 font-medium text-gray-900 tracking-wide uppercase">Pay attention:</h3>
                       <ul class="mt-6 space-y-4">
                           <li class="flex space-x-3">
                               <!-- Heroicon name: check -->
                               <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                   <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                               </svg>
                               <span class="text-sm leading-5 text-gray-500">Is my community name offensive?</span>
                           </li>

                           <li class="flex space-x-3">
                               <!-- Heroicon name: check -->
                               <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                   <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                               </svg>
                               <span class="text-sm leading-5 text-gray-500">Do I plan to misuse the system?</span>
                           </li>

<!--                           <li class="flex space-x-3">-->
<!--                               &lt;!&ndash; Heroicon name: check &ndash;&gt;-->
<!--                               <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">-->
<!--                                   <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />-->
<!--                               </svg>-->
<!--                               <span class="text-sm leading-5 text-gray-500">Is my community offensive?</span>-->
<!--                           </li>-->

                           <li class="flex space-x-3">
                               <!-- Heroicon name: check -->
                               <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                   <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                               </svg>
                               <span class="text-sm leading-5 text-gray-500">Am I spamming?</span>
                           </li>

                           <li class="flex space-x-3">
                               <!-- Heroicon name: check -->
                               <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                   <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                               </svg>
                               <span class="text-sm leading-5 text-gray-500">Serious violators will be banned</span>
                           </li>
                       </ul>
                   </div>
               </div>
           </div>
       </div>


       <alertNotification :open="showAlertNotification" notificationType="alert">
           <template v-slot:header>
               Failed to create a new community!
           </template>
           <template v-slot:text>
               Please check your naming. It might exist already or include forbidden character.
           </template>
       </alertNotification>

<!--       <link rel="stylesheet" href="https://pagecdn.io/lib/font-awesome/5.10.0-11/css/all.min.css" integrity="sha256-p9TTWD+813MlLaxMXMbTA7wN/ArzGyW/L7c5+KkjOkM=" crossorigin="anonymous">-->
   </div>

</template>

<script>
    import Notification from '../components/ActionNotification'
    import { required } from 'vuelidate/lib/validators'
    import Selection from '../components/Selection'
    import {mapGetters} from 'vuex'
    import _ from 'lodash'
    import * as fb from '../firebaseConfig'
    import * as axios from '../axios-auth'

    export default {
        name: "NewCommunity",
        components: {
            alertNotification: Notification,
            selection: Selection,
        },
        data() {
            return {
                focus: null,
                selectionOpen: false,
                showAlertNotification: false,
                orgs: [],
                org: {
                    username: null,
                    avatar_url: null,
                },
                isPrivate: false,
                proError: false,
                selectedOwner: null,
                description: '',
                communityName: '',
                nameAvailable: null,
                // open: false,
            }
        },
        validations: {
            description: {
                required,
            },
            communityName: {
                required,
                allowedCharacters(name1){
                    const regex = new RegExp(`^[-_A-z0-9\\s]*((-|\\s)*[_A-z0-9])*$`)
                    return (regex.test(name1))
                }
            },
            isPrivate: {
                required,
            }
        },
        created() {
            this.selectedOwner = this.getUser
            //getOrganizations
        },
        computed: {
            ...mapGetters(['getUsername', 'getToken', 'getUser', 'isPremium']),
            isNameAvailable(){
                return (this.communityName.trim() !== '' && this.nameAvailable)
            }

        },
        watch: {
            communityName(){
                this.nameAvailable = null
            }
        },
        methods: {
            checkNameAvailability: _.debounce(function (){
                if(this.communityName.trim() === ""){
                    return
                }
                const newName = this.communityName ? this.communityName.replace(/\s/g, "-") : ""
                console.log("username and communityname:", this.getUsername, this.communityName)
                this.nameAvailable = null
                axios.jigo.get(`/v2/community/isNameAvailable/${this.getUsername}/${newName}`)
                    .then((resp) => {
                        console.log("response", resp.data)
                        if (resp.status === 200){
                            this.nameAvailable = resp.data
                        }
                    })
                    .catch((err) => {
                        console.error("ERROR: check names availability FAILED", err)
                    })
            },500),
            newCommunity(){
                this.$v.$touch()
                if(this.$v.$invalid || !this.nameAvailable) {
                    console.log("ERRORS")
                    return
                }

                const newCommunityRequest = {
                    name: this.communityName,
                    description: this.description,
                    isPrivate: this.isPrivate,
                }

                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });

                // const apiUrl = 'user/communities'

                axios.jigo.post('/v2/user/communities', newCommunityRequest,{validateStatus: (status) =>{
                        return status < 500
                    }})
                    .then((resp) => {
                        if(resp.status !== 200 ) {
                            this.showAlertNotification = true;
                            setTimeout(() => this.showAlertNotification = false, 5000);
                            console.error("Unwanted status returned", resp.status)
                            loader.hide()
                            return
                        }
                        loader.hide()
                        this.$router.push(`/${this.selectedOwner.username}/${newCommunityRequest.name.replace(/\s/g, "-")}`)
                    })
                    .catch(err => {
                        this.showAlertNotification = true;
                        setTimeout(() => this.showAlertNotification = false, 5000);
                        loader.hide()
                        console.log(err)
                    })

            },
            createCommunity() {
                this.$v.$touch()
                if(this.$v.$invalid) {
                    console.log("returning")
                    return
                }
                const newName = this.communityName ? this.communityName.replace(/\s/g, "_-") : ""
                const newCommunity = {
                    auto_init: true,
                    description: this.description,
                    gitignores: "",
                    issue_labels: "",
                    license: "",
                    name: newName,
                    private: this.isPrivate,
                    readme: ""
                }

                const newCommunityRequest = {
                    community: newCommunity,
                    owner: this.selectedOwner.username,
                    uid: fb.auth.currentUser.uid,
                    email: fb.auth.currentUser.email,
                }

                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });

                axios.jigo.post('/newCommunity', newCommunityRequest,{validateStatus: (status) => {
                        return status < 500
                    }})
                    .then(res => {
                        if(res.status === 201){
                            console.log(res.data)
                            loader.hide()
                            this.$analytics.logEvent("New_Community")
                            var metadata = {
                                communityName: this.communityName,
                                communitySavedName: newName,

                            }
                            window.Intercom('trackEvent', 'createdCommunity', metadata);
                            this.$router.push(`/${newCommunityRequest.owner}/${newCommunityRequest.community.name}`)
                        } else if(res.status === 403){
                            if(res.data.error_type === "Pro"){
                                this.proError = true
                                this.showAlertNotification = true;
                                setTimeout(() => this.showAlertNotification = false, 5000);
                            }
                        }
                        loader.hide()
                    })
                    .catch(err => {
                        this.showAlertNotification = true;
                        setTimeout(() => this.showAlertNotification = false, 5000);
                        loader.hide()
                        console.log(err)
                    })
                // if(!this.$v.$invalid){
                //
                // }
            },
            loaderCanceled(){
                console.log("Loader canceled")
            },
            toggleSelect(){
                this.selectionOpen = !this.selectionOpen
            },
            ownerSelected(owner){
                this.selectedOwner = owner
            },
            communityNameBlurred(){
                this.$v.communityName.$touch()
                this.focus = null
            },
            communityNameFocused(){
                this.focus = "communityName"
            },
        }
    }
</script>

<style scoped>
    .input.invalid input {
        border: 1px solid red;
        background-color: beige;
    }
</style>
