<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="mt-8 pb-12 bg-jiruto-main sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
            <div class="relative">
                <div class="absolute inset-0 h-3/4 bg-jiruto-main"></div>
                <div class="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="max-w-md mx-auto lg:max-w-7xl flex flex-col lg:flex-row lg:gap-10">
<!--                    <div class="max-w-md mx-auto lg:max-w-7xl lg:grid lg:grid-cols-3 lg:gap-10">-->

                        <!--Card Start -->
                        <div class="rounded-lg shadow-xl-white overflow-hidden col-span-1 w-full self-start" :class="{'lg:w-2/6': cardNeeded}">
                            <div class="flex px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                <div>
                                    <div>
                                    <span class="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600">
                                        JIRUTO PRO
                                    </span>
                                    </div>
                                    <div class="flex my-5 items-center Capitalize">
                                        <p class="capitalize">
                                            {{plans[0].billingCycle + "ly"}}
                                        </p>

                                        <span :class="{ 'bg-gray-200': !planValue, 'bg-indigo-600': planValue }" class="relative mx-2 inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline" role="checkbox" tabindex="0" @click="togglePlan()" @keydown.space.prevent="togglePlan()" :aria-checked="planValue.toString()">
                                        <span aria-hidden="true" :class="{ 'translate-x-5': planValue, 'translate-x-0': !planValue }" class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"></span>
                                    </span>
                                        <p class="capitalize">
                                            {{plans[1].billingCycle + "ly"}}
                                        </p>

                                        <p class="font-bold mx-2 text-jiruto-zotMain text-lg"> save 20% </p>
                                    </div>
                                    <div class="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                                        ${{ plans[planValueInt].price }}
                                        <span class="ml-1 text-2xl leading-8 font-medium text-gray-500">
                                        /{{plans[planValueInt].billingCycle}}
                                    </span>
                                    </div>
                                    <p class="mt-5 text-lg leading-7 text-gray-500">
                                        Enjoy the benefits of the Pros.
                                    </p>
                                </div>
<!--                                <div><p>Yo</p></div>-->
                            </div>
                            <div class="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                                <ul>
                                    <li class="flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                            All the free plan features
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                            Unlimited private communities
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                            Collaborate on private communities
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                            Help a budding education startup :)
                                        </p>
                                    </li>
                                </ul>
                                <div v-if="!cardNeeded" class="mt-6 rounded-md shadow">
                                    <button @click="updatePlan" class="flex w-full items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                        Update plan
                                    </button>
                                </div>
                                <div class="mt-4" v-if="updateFailure">
                                    <p class="text-red-400">
                                        *Plan update failed. Please try again later or contact our
                                        <span>
                                            <a href="mailto:learn@jiruto.com" class="text-base leading-6 text-indigo-500 hover:text-gray-900">
                                                support
                                            </a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!--Card End-->


                        <!-- Card Start -->

                        <div v-if ="cardNeeded" id="payment-form" class="mt-4 rounded-lg shadow-xl-white overflow-hidden lg:mt-0 col-span-2 lg:w-4/6 w-full lg:ml-10 self-start">
                            <form @submit.prevent="cardAndPay">
                            <div class="px-6 py-8 bg-white sm:px-10 sm:pb-6">

                                <div class="bg-white">
                                    <fieldset>
                                        <legend class="block text-sm font-medium leading-5 text-gray-700">Card Details</legend>
                                        <div class="mt-1 rounded-md shadow-sm">
                                            <div>
                                                <input v-model="cardHolderName" aria-label="Cardholder Name" class="form-input relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="Card Holder Name  " />
                                            </div>
                                            <div class="-mt-px">
                                                <div id="card-element" class="MyCardElement form-input relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                                    <!-- Elements will create input elements here -->
                                                </div>
                                            </div>
                                            <!-- We'll put the error messages in this element -->
                                            <div id="card-errors text-red-500" role="alert"></div>
                                        </div>
                                    </fieldset>
                                </div>

                                <div class="bg-white">
                                    <fieldset class="mt-5">
                                        <legend class="block text-sm font-medium leading-5 text-gray-700">Billing Details</legend>
                                        <div class="mt-1 bg-white rounded-md shadow-sm">
                                            <div class="-mt-px flex">
                                                <div class="w-1/2 flex-1 min-w-0">
                                                    <input v-model="billing.country" aria-label="Country" class="form-input relative block w-full rounded-none rounded-tl-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="Country" />
                                                </div>
                                                <div class="-ml-px flex-1 min-w-0">
                                                    <input v-model="billing.address" aria-label="Address" class="form-input relative block w-full rounded-none rounded-tr-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="Address" />
                                                </div>
                                            </div>
                                            <div class="-mt-px flex">
                                                <div class="w-1/2 flex-1 min-w-0">
                                                    <input v-model="billing.city" aria-label="City" class="form-input relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="City" />
                                                </div>
                                                <div class="-ml-px flex-1 min-w-0">
                                                    <input v-model="billing.postalCode" aria-label="Postal" class="form-input relative block w-full rounded-none rounded-br-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="Postal Code" />
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>



                            </div>
                            <div class="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                                <ul>
                                    <li class="flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                            All Free features
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                            Create unlimited private communities
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                            500BG account storage limit
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                            Collaborate on private communities
                                        </p>
                                    </li>
                                </ul>
                                <div class="mt-6 rounded-md shadow">
                                    <button type="submit" class="flex w-full items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                        Pay and upgrade
                                    </button>
                                </div>
                            </div>
                            </form>

                        </div>
                        <!--Card End-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex'
    import * as fb from '../firebaseConfig'
    import * as axios from '../axios-auth'
    const style = {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    };

    import {loadStripe} from '@stripe/stripe-js';

    export default {
        name: "Upgrade",
        props: [],
        components: {
            // elements: Elements,
        },
        data() {
            return {
                cardElements: {
                    cardNumber: null,
                    cardCVC: null,
                    cardExp: null,
                    cardPostal: null,
                },
                billing: {
                    country: "",
                    city: "",
                    address: "",
                    postalCode: ""
                },
                cardNeeded: false,
                cardHolderName: "",
                stripe: null,
                elements: null,
                cardElement: null,
                planValue: false,
                updateFailure: false,
                plans: [{name: "Jiruto Pro Monthly", price: 9, billingCycle: "month"}, {name: "Jiruto Pro Yearly", price: 90, billingCycle: "year"}]
            }
        },
        created(){
            axios.jigo.post("/getDefaultPaymentCard", fb.auth.currentUser.uid ,{headers: {"Content-Type": "text/plain"}, validateStatus: (status) =>{
                    return status < 500}})
                .then((resp) => {
                    if (resp.status !== 200){
                        this.cardNeeded = true
                    }
                }).catch((err) => {
                    console.error("Error: ", err.response.data)
            })
        },
        watch: {
            cardNeeded(){
                if (this.cardNeeded === true){
                    this.initStripe()
                }
            }
        },
        methods: {
            togglePlan(){
                this.planValue = !this.planValue
            },
            async initStripe(){

                this.stripe = await loadStripe('pk_live_6pmnAoot9pBPEniblfpG2qD5');
                if(this.$store.getters.getUsername === "learn" || this.$store.getters.getUsername === "sumone"){
                    this.stripe = await loadStripe('pk_test_2ZTUYx6oJfmHUN3X6qjEDtw9');
                    // this.stripe = window.Stripe('pk_test_2ZTUYx6oJfmHUN3X6qjEDtw9');
                }
                this.elements = this.stripe.elements()
                this.cardElement = this.elements.create("card", { style: style });
                // this.cardElement.cardCVC = this.element.create("")
                this.cardElement.mount("#card-element");

                this.cardElement.addEventListener('change', function(event) {
                    let displayError = document.getElementById('card-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });
            },
            cardAndPay(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.post("/getSetupIntent", {uid: fb.auth.currentUser.uid}, {validateStatus: (status) => {
                    return status < 500
                    }})
                    .then((resp) => {
                        return  this.stripe.confirmCardSetup(
                            resp.data.clientSecret, {
                                payment_method: {
                                    card: this.cardElement,
                                    billing_details: {
                                        name: this.cardHolderName,
                                        email: fb.auth.currentUser.email
                                        // billing: this.billing,
                                    }}})
                    })
                    .then((resp) => {
                        if (resp.error){
                            loader.hide();
                            console.error("Error when checking the card", resp.error)
                            Promise.reject(resp.error)
                        } else {
                            const defaultPMRequest = {
                                uid: fb.auth.currentUser.uid,
                                paymentMethodID: resp.setupIntent.payment_method,
                                billing: this.billing,

                            }
                            return axios.jigo.post('setDefaultPM', defaultPMRequest, {validateStatus: (status) =>{
                                    return status < 500}})
                        }
                    })
                    .then((resp) => {
                        loader.hide()
                        if(resp.status === 201){
                            return this.updatePlan()
                        } else {
                            console.error("coudln't set default PM", resp.status, resp.data)
                            Promise.reject(resp)
                        }
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("Some error occurred in the process of upgrading", err)
                    })
            },
            updatePlan(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                const paymentRequest = {
                    email: fb.auth.currentUser.email,
                    plan: this.plans[this.planValueInt].name,
                    uid: fb.auth.currentUser.uid,
                    waitForEndOfPeriod: false,
                };
                return axios.jigo.post('changeSubPlan', paymentRequest, {validateStatus: (status) =>{
                        return status < 500}})
                    .then((resp) => {
                        loader.hide()
                        if(resp.status === 201) {
                            this.$store.dispatch('getUserData')
                                .then(this.$router.push({name: 'proEducation'}))
                                .catch((err) => console.error("Some error while fetching the user",err))
                        }else {
                            this.updateFailure = true
                            console.error("Something went wrong!", resp.status, resp.data)
                        }
                    }).catch((err) => {
                        loader.hide()
                        this.updateFailure = true
                        console.error("Something went wrong while trying to update the plan: ", err)
                    })
            },
            paymentSubmitted(){
                this.stripe.createPaymentMethod({
                    type: 'card',
                    card: this.cardElement,
                    billing_details: {
                        email: fb.auth.currentUser.email,
                    },
                }).then((resp) => {
                    console.error("Something was returned", resp)
                    this.stripePaymentMethodHandler(resp, resp.paymentMethod.billing_details.email)
                }).catch((err) => console.error("Error while trying to submit payment ", err));
            },
            stripePaymentMethodHandler(result) {
                if (result.error) {
                    console.error(result.error)
                } else {
                    let loader = this.$loading.show({
                        onCancel: this.loaderCanceled,
                    });
                    // Otherwise send paymentMethod.id to your server
                    const shouldUpdatePaymentMethod = this.cardNeeded ? true : false
                    const paymentRequest = {
                        email: fb.auth.currentUser.email,
                        paymentMethodID: result.paymentMethod.id,
                        plan: this.plans[this.planValueInt].name,
                        uid: fb.auth.currentUser.uid,
                        shouldUpdatePaymentMethod: shouldUpdatePaymentMethod
                    };
                    axios.jigo.post('changeSubPlan', paymentRequest, {validateStatus: (status) =>{
                            return status < 500}}).then((resp) => {
                        loader.hide()
                        if(resp.status === 201) {
                            this.$store.dispatch('getUserData')
                                .then(this.$router.push({name: 'proWelcome'}))
                                .catch((err) => console.error("Some error while fetching the user",err))
                        }else {
                            console.error("Something went wrong!", resp.status, resp.data)
                        }
                    }).catch((err) => {
                        loader.hide()
                        console.error("Error while creating a subscription: ", err, err.response.data)
                    });
                }
            },
        },
        computed: {
            ...mapGetters(['getUser']),
            planValueInt(){
                return this.planValue ? 1 : 0;
            }
        },
    }
</script>

<style scoped>

    /*.StripeElement {*/
    /*    box-sizing: border-box;*/

    /*    height: 40px;*/

    /*    padding: 10px 12px;*/

    /*    border: 1px solid transparent;*/
    /*    border-radius: 4px;*/
    /*    background-color: white;*/

    /*    box-shadow: 0 1px 3px 0 #e6ebf1;*/
    /*    -webkit-transition: box-shadow 150ms ease;*/
    /*    transition: box-shadow 150ms ease;*/
    /*}*/

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    /*.StripeElement--invalid {*/
    /*    border-color: #fa755a;*/
    /*}*/

    /*.StripeElement--webkit-autofill {*/
    /*    background-color: #fefde5 !important;*/
    /*}*/

</style>


<!--                                <div>-->
<!--                                    <div class="bg-white">-->
<!--                                        <fieldset class="mt-5">-->
<!--                                            <legend class="block text-sm font-medium leading-5 text-gray-700">Card Details</legend>-->
<!--                                            <div class="mt-1 bg-white rounded-md shadow-sm">-->
<!--                                                <div class="-mt-px flex">-->
<!--                                                    <div class="w-1/2 flex-1 min-w-0">-->
<!--                                                        <input v-model="cardHolderName" aria-label="Card holder name" class="form-input relative block w-full rounded-none rounded-tl-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="Card Holder Name" />-->
<!--                                                    </div>-->
<!--                                                    <div class="-ml-px flex-1 min-w-0">-->
<!--                                                        <input aria-label="Card number" class="form-input relative block w-full rounded-none rounded-tr-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="Card Number" />-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                                <div class="-mt-px flex">-->
<!--                                                    <div class="w-1/2 flex-1 min-w-0">-->
<!--                                                        <input aria-label="Card expiration date" class="form-input relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="MM / YY" />-->
<!--                                                    </div>-->
<!--                                                    <div class="-ml-px flex-1 min-w-0">-->
<!--                                                        <input aria-label="Card CVC" class="form-input relative block w-full rounded-none rounded-br-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="CVC" />-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </fieldset>-->
<!--                                    </div>-->

<!--                                    <div class="mt-6 bg-white">-->
<!--                                        <fieldset>-->
<!--                                            <legend class="block text-sm font-medium leading-5 text-gray-700">Billing address</legend>-->
<!--                                            <div class="mt-1 rounded-md shadow-sm">-->
<!--                                                <div>-->
<!--                                                    <select aria-label="Country" class="form-select relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5">-->
<!--                                                        <option>USA</option>-->
<!--                                                        <option>Canada</option>-->
<!--                                                    </select>-->
<!--                                                </div>-->
<!--                                                <div class="-mt-px">-->
<!--                                                    <input aria-label="Address" class="form-input relative block w-full rounded-none bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="Address" />-->
<!--                                                </div>-->
<!--                                                <div class="-mt-px">-->
<!--                                                    <input aria-label="Postal code" class="form-input relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="Postal code" />-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </fieldset>-->
<!--                                    </div>-->
<!--                                </div>-->
