<template>
    <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->

    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 sm:py-6 lg:py-8">
        <div class="flex flex-col 2xl:flex-row justify-evenly gap-4">
            <div class="bg-white rounded-md sm:px-6 lg:px-8 sm:py-6 lg:py-6 order-2 2xl:order-1 ">
                <div class="bg-white rounded-md">
                    <div>
                        <div class="">
                            <div>
                                <h3 class="text-lg leading-6 font-medium text-gray-900">
                                    New Group Session
                                </h3>
                                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                    Group sessions are super exciting!
                                </p>
                            </div>
                            <div class="mt-6 sm:mt-5">
                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                        Topic
                                    </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                            <input type="text" autocomplete="off" @focusin="topicFocusIn" @focusout="topicFocusOut" v-model="groupSession.topic" id="first_name" class="block border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Give your session a topic"
                                                   :class="{'placeholder-red-500 border border-red-500': $v.groupSession.topic.$error}">
                                        </div>
<!--                                        <p v-if="topicValid === false" class="text-sm text-red-500">Topic is required</p>-->
                                    </div>
                                </div>

                                <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label for="agenda" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                        Agenda
                                    </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg flex rounded-md shadow-sm">
                                            <textarea v-model="groupSession.agenda" id="agenda" rows="7" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" :placeholder="placeholder"></textarea>
                                        </div>
                                        <p class="mt-2 text-sm text-black">Example:</p>
                                        <div class="">
                                            <p class="mt-2 text-xs text-gray-700">This session will include 3 rounds:</p>
                                            <div class="ml-5">
                                                <p class=" text-xs text-gray-700">Round 1: Introduce yourself and your understanding of the topic</p>
                                                <p class=" text-xs text-gray-700">Round 2: What don't you understand?</p>
                                                <p class=" text-xs text-gray-700">Round 3: Describe how you would implement it</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-6 sm:mt-5 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <div role="group" aria-labelledby="label-notifications">
                                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                                            <div>
                                                <div class="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700" id="label-notifications2">
                                                    Group Session Type
                                                </div>
                                            </div>
                                            <div class="sm:col-span-2">
                                                <div class="max-w-lg">
<!--                                                    <p class="text-sm leading-5 text-gray-500">These are delivered via SMS to your mobile phone.</p>-->
                                                    <div class="">
<!--                                                    <div class="mt-4">-->
                                                        <div class="flex items-center">
                                                            <input id="private_session" @change="sessionSubTypeChanged" name="private_session" value="private" v-model="groupSession.sessionType" type="radio" class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out border"
                                                                   :class="{'border-red-500': $v.groupSession.sessionType.$error}">
                                                            <label for="private_session" class="ml-3">
                                                                <span class="block text-sm leading-5 font-medium text-gray-700">Private session (invite colearners)</span>
                                                            </label>
                                                        </div>
                                                        <div class="mt-4 flex items-center">
                                                            <input id="community_session" @change="sessionSubTypeChanged" name="community_session" value="community" type="radio" v-model="groupSession.sessionType" class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out border"
                                                                   :class="{'border-red-500': $v.groupSession.sessionType.$error}">
                                                            <label for="community_session" class="ml-3">
                                                                <span class="block text-sm leading-5 font-medium text-gray-700">Community session (subscribers are free to join)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div v-if="groupSession.sessionType === 'private'" class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <div class="flex flex-col">
                                        <label for="searchCoLearners" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                            Invite Participants
                                        </label>
                                        <p class="text-xs text-gray-500">* you must be colearners</p>
                                    </div>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="">
                                            <label for="searchCoLearners" class="sr-only">Search colearners</label>
                                            <div class="relative rounded-md shadow-sm sm:max-w-xs">
                                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <!-- Heroicon name: search -->
                                                    <svg class="mr-3 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                                <input autocomplete="off" v-model="coLearnerSearchTerm" id="searchCoLearners" @focus="searchCoLearners" @input="searchCoLearners"
                                                       class="form-input block w-full pl-9 sm:text-sm sm:leading-5" placeholder="Search CoLearners">
                                            </div>
                                            <userSelection v-if="showCoLearnerSelection" :users="coLearnersList" @close="showCoLearnerSelection = false"
                                                           @userSelected="addParticipant"  :open="showCoLearnerSelection" class="z-50 w-full "/>
                                        </div>
                                        <ul class="rounded-md sm:max-w-xs mt-2">
                                            <li v-for="participant in groupSession.invitees" :key="participant.user.JID" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                                                <div class="w-0 flex-1 flex items-center">
                                                    <!-- Heroicon name: paper-clip -->
                                                    <img :src="participant.user.avatarUrl" alt="" class="flex-shrink-0 h-6 w-6 rounded-full">
                                                    <span class="ml-2 flex-1 w-0 truncate">
                                                      {{ participant.user.firstName + " " + participant.user.lastName }}
                                                    </span>
                                                    <span class="ml-2 flex-1 w-0 text-gray-400">
                                                      @{{ participant.user.username }}
                                                    </span>
                                                </div>
                                                <div class="ml-4 flex-shrink-0 flex items-center">
                                                    <button @click="removeParticipant(participant.user.JID)" class="text-gray-400 hover:text-gray-500">
                                                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                                    </button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div v-if="groupSession.sessionType === 'community'" class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <div class="flex flex-col">
                                        <label for="searchCommunity" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                            Choose Learning Community
                                        </label>
                                        <p class="text-xs text-gray-500">* you must be subscribed</p>
                                    </div>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="">
                                            <label for="searchCommunity" class="sr-only">Search communities</label>
                                            <div v-if="groupSession.community === null" class="relative flex rounded-md shadow-sm sm:max-w-xs" :class="{'border-red-500 border': $v.groupSession.community.$error}">
                                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <!-- Heroicon name: search -->
                                                    <svg class="mr-3 h-4 w-4 text-gray-400" :class="{'text-red-500': $v.groupSession.community.$error}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                                <input type="text" v-model="communitySearchTerm" id="searchCommunity" @focus="searchCommunitiesFocusin" @input="searchCommunities" @focusout="searchCommunitiesFocusOut"
                                                       class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border form-input block w-full pl-8   sm:text-sm sm:leading-5" placeholder="Search communities" :class="{'placeholder-red-500': $v.groupSession.community.$error}">
                                            </div>
                                            <div v-else class="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5 border rounded-md">
                                                <div id="listbox-item-0" role="option"
                                                    class="text-gray-900 cursor-default select-none relative">
                                                    <div class="flex items-center space-x-3">
                                                        <span class="font-normal block truncate">
                                                            {{ groupSession.community.name }}
                                                            <span class="text-gray-400">by @{{
                                                                    groupSession.community.owner.username
                                                                }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="ml-4 flex-shrink-0 flex items-center">
                                                    <button @click="unselectCommunity" class="text-gray-400 hover:text-gray-500">
                                                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <community-selection v-if="showCommunitySelection" :communities="subscriptionList" @close="showCommunitySelection = false" :open="showCommunitySelection"
                                                                 class="z-50 w-full " @selected="communitySelected"></community-selection>
                                        </div>
                                    </div>
                                </div>


                                <div v-if="showAdminSubType" class="mt-6 sm:mt-5 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <div role="group" aria-labelledby="label-notifications">
                                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                                            <div>
                                                <div class="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700" id="label-notifications">
                                                    Community Session Options
                                                </div>
                                            </div>
                                            <div class="sm:col-span-2">
                                                <div class="max-w-lg">
                                                    <!--                                                    <p class="text-sm leading-5 text-gray-500">These are delivered via SMS to your mobile phone.</p>-->
                                                    <div class="">
                                                        <!--                                                    <div class="mt-4">-->
                                                        <div class="flex items-center">
                                                            <input id="single_room" @change="sessionSubTypeChanged" name="single_room" value="singleRoom" v-model="groupSession.sessionSubType" type="radio" class="form-radio border h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                                   :class="{'border-red-500': $v.groupSession.sessionSubType.$error}">
                                                            <label for="single_room" class="ml-3">
                                                                <span class="block text-sm leading-5 font-medium text-gray-700">Single room</span>
                                                            </label>
                                                        </div>
                                                        <div class="mt-4 flex items-center">
                                                            <input id="multi_room_lobby" @change="sessionSubTypeChanged" name="multi_room_lobby" value="multiRoomLobby" type="radio" v-model="groupSession.sessionSubType" class="form-radio border h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                                   :class="{'border-red-500': $v.groupSession.sessionSubType.$error}">
                                                            <label for="multi_room_lobby" class="ml-3">
                                                                <span class="block text-sm leading-5 font-medium text-gray-700">Multi room</span>
                                                            </label>
                                                        </div>
                                                        <div class="mt-4 flex items-center">
                                                            <input id="podium" name="podium" @change="sessionSubTypeChanged" value="podium" type="radio" v-model="groupSession.sessionSubType" class="form-radio border h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                                   :class="{'border-red-500': $v.groupSession.sessionSubType.$error}">
                                                            <label for="podium" class="ml-3">
                                                                <span class="block text-sm leading-5 font-medium text-gray-700">Podium (e.g lecture: up to 200 participants)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <p class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                Date and Time
                            </p>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div>
                                    <vc-date-picker :input-debounce="500" :is24hr="true" v-model="groupSession.startsAt"
                                                    :mode="vDatePickerMode" :timezone="timezone" :min-date="minDate" >
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input
                                                    class="bg-white border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border px-2 py-1 rounded"
                                                    :value="inputValue"
                                                    v-on="inputEvents"
                                                    @focusin="sessionDateFocusIn"
                                                    @focusout="sessionDateFocusOut"
                                                    placeholder="choose a date"
                                                    :class="{'border-red-500 placeholder-red-500' : $v.groupSession.startsAt.$error}"
                                            />
                                        </template>
                                    </vc-date-picker>
                                    <div v-if="groupSession.sessionSubType === 'multiRoomLobby'" class="max-w-lg mt-5">
                                        <!--                                                    <p class="text-sm leading-5 text-gray-500">These are delivered via SMS to your mobile phone.</p>-->
                                        <div class="">
                                            <!--                                                    <div class="mt-4">-->
                                            <div class="flex items-center">
                                                <input id="first_option" @change="hourChanged" name="first_option" value="first" v-model="multiRoomHour" type="radio" class="border h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                       :class="{'border-red-500': $v.groupSession.sessionSubType.$error}">
                                                <label for="first_option" class="ml-3">
                                                    <span class="block text-sm leading-5 font-medium text-gray-700">{{ hourString(firstHour) }}</span>
                                                </label>
                                            </div>
                                            <div class="mt-4 flex items-center">
                                                <input id="second_option" @change="hourChanged" name="second_option" value="second" type="radio" v-model="multiRoomHour" class="border h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                       :class="{'border-red-500': $v.groupSession.sessionSubType.$error}">
                                                <label for="second_option" class="ml-3">
                                                    <span class="block text-sm leading-5 font-medium text-gray-700">{{ hourString(secondHour) }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
<!--                                {{ groupSession.startsAt }}-->
<!--                                <br>-->
<!--                                {{ groupSession.sessionType }}-->
<!--                                <br>-->
<!--                                {{ groupSession.sessionSubType }}-->
<!--                                <br>-->
                            </div>
                        </div>
                    </div>
                    <div class="mt-8 border-t border-gray-200 pt-5">
                        <div class="flex justify-end">
                            <span class="inline-flex rounded-md shadow-sm">
                                <button type="button" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                    Cancel
                                </button>
                            </span>
                            <span class="ml-3 inline-flex rounded-md shadow-sm">
                                <button @click="createPressed" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                    Create
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="order-1 2xl:order-2">
                <div class="border bg-purple-100 border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200s">
                    <div class="p-6">
                        <h2 class="text-lg leading-6 font-medium text-gray-900">Before you schedule</h2>
                        <p class="mt-4 text-sm leading-5 text-gray-500">Make sure you're following our general guidelines</p>
                    </div>
                    <div class="pt-6 pb-8 px-6">
                        <h3 class="text-xs leading-4 font-medium text-gray-900 tracking-wide uppercase">Pay attention:</h3>
                        <ul class="mt-6 space-y-4">
                            <li class="flex space-x-3">
                                <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                                <span class="text-sm leading-5 text-gray-500">Am I being considerate towards my CoLearners?</span>
                            </li>

                            <li class="flex space-x-3">
                                <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                                <span class="text-sm leading-5 text-gray-500">Is my topic relevant?</span>
                            </li>

                            <li class="flex space-x-3">
                                <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                                <span class="text-sm leading-5 text-gray-500">Is my topic offensive?</span>
                            </li>

                            <li class="flex space-x-3">
                                <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                                <span class="text-sm leading-5 text-gray-500">Am I spamming?</span>
                            </li>

                            <li class="flex space-x-3">
                                <!-- Heroicon name: check -->
                                <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                                <span class="text-sm leading-5 text-gray-500">Serious violators will be banned</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>




</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    import * as _ from 'lodash'
    import * as axios from '@/axios-auth'
    import UserSelection from "@/components/UserSelection";
    import CommunitySelection from "@/components/CommunitySelection";
    export default {
        name: "NewGroupSession",
        components: {CommunitySelection, UserSelection},
        props: {
            communityOwner: {
                default: null
            },
            communityName: {
                default: null
            },
        },
        data(){
            return {
                multiRoomHour: "first",
                vDatePickerMode: "dateTime",
                groupSession: {
                    topic: "",
                    agenda: "",
                    startsAt: new Date(),
                    sessionType: "",
                    sessionSubType: "singleRoom",
                    community: null,
                    invitees: [],
                    attendees: [],
                },
                timezone: '',
                placeholder: "What's on the agenda?",
                coLearnersList: [],
                showCoLearnerSelection: false,
                showCommunitySelection: false,
                coLearnerSearchTerm: "",
                communitySearchTerm: "",
                subscriptionList: [],
            }
        },
        created(){
            if (this.communityOwner !== null && this.communityName !== null) {
                this.groupSession.sessionType = "community"
                this.groupSession.community = {name: this.communityName, owner: {username: this.communityOwner}}
            }
        },
        validations() {
            if(this.groupSession.sessionType === "community"){
                return {
                    groupSession: {
                        topic: {
                            required,
                        },
                        sessionType: {
                            required
                        },
                        startsAt: {
                            required
                        },
                        sessionSubType: {
                            required
                        },
                        community: {
                            required,
                        }
                    },
                }
            } else {
                return {
                    groupSession: {
                        topic: {
                            required,
                        },
                        sessionType: {
                            required
                        },
                        startsAt: {
                            required
                        },
                        sessionSubType: {
                        }
                    }
                }
            }

        },
        computed: {
            minDate(){
                const now = new Date()
                let newDate = ""
                if (this.groupSession.sessionType === "community" && this.groupSession.sessionSubType === "multiRoomLobby") {
                    newDate =  now.setDate(now.getDate() + 1)
                } else {
                    newDate =  now.setDate(now.getDate())
                }
                return new Date(newDate)
            },
            sixPM(){
                let sixPM
                if(this.minDate < this.groupSession.startsAt) {
                    sixPM = new Date(this.groupSession.startsAt)
                } else {
                    sixPM = new Date(this.minDate)
                }
                sixPM.setUTCHours(18, 0, 0 , 0)
                return sixPM
            },
            fiveAM(){
                let fiveAM
                if(this.minDate < this.groupSession.startsAt) {
                    fiveAM = new Date(this.groupSession.startsAt)
                } else {
                    fiveAM = new Date(this.minDate)
                }
                fiveAM.setUTCHours(5, 0, 0, 0)
                return fiveAM
            },
            firstHour(){
                this.fiveAM.getTime()
                return this.fiveAM.getHours() < this.sixPM.getHours() ? this.fiveAM.getHours() : this.sixPM.getHours()
            },
            secondHour(){
                return this.fiveAM.getHours() > this.sixPM.getHours() ? this.fiveAM.getHours() : this.sixPM.getHours()
            },
            firstHourFull(){
                return this.fiveAM.getHours() < this.sixPM.getHours() ? this.fiveAM : this.sixPM
            },
            secondHourFull(){
                return this.fiveAM.getHours() > this.sixPM.getHours() ? this.fiveAM : this.sixPM
            },
            showAdminSubType(){
                return this.groupSession.community !== null &&
                        this.groupSession.sessionType === "community" &&
                        this.groupSession.community.owner.username === this.$store.getters.getUsername
            },
            // chosenHour(){
            //     if(this.multiRoomHour === "first"){
            //         this.groupSession.startsAt = this.firstHourFull
            //     }
            //     if(this.multiRoomHour === "second"){
            //         this.groupSession.startsAt = this.secondHourFull
            //     }
            // },
            // chosenHourFull(){
            //     if(this.multiRoomHour === "first"){
            //         this.groupSession.startsAt = this.firstHourFull
            //     }
            //     if(this.multiRoomHour === "second"){
            //         this.groupSession.startsAt = this.secondHourFull
            //     }
            // }
        },
        watch: {
        },
        methods: {
            hourString(hour){
                let AMorPM = "am"
                if(hour >= 12){
                    AMorPM = "pm"
                }
                if(hour > 12) {
                    hour = hour - 12
                }
                return (hour + ":" + "00" + " " + AMorPM)
            },
            hourChanged(){
                if(this.multiRoomHour === "first"){
                    this.groupSession.startsAt = this.firstHourFull
                    console.log("first is", this.groupSession.startsAt)
                }
                if(this.multiRoomHour === "second"){
                    this.groupSession.startsAt = this.secondHourFull
                    console.log("secondo is", this.groupSession.startsAt)
                }

            },
            sessionSubTypeChanged(){
                // let newDate = new Date(this.minDate)
                if(this.groupSession.sessionSubType === "multiRoomLobby") {
                    this.vDatePickerMode = "date"
                    this.groupSession.startsAt = this.firstHourFull

                } else {
                    this.vDatePickerMode = "dateTime"
                }

            },
            singleRoomSelected(){
                this.vDatePickerMode = "dateTime"
            },

            sessionDateFocusIn(){
              this.$v.groupSession.startsAt.$reset()
            },
            sessionDateFocusOut(){
                this.$v.groupSession.startsAt.$touch()
            },
            topicFocusIn(){
                this.$v.groupSession.topic.$reset()
            },
            topicFocusOut(){
                this.$v.groupSession.topic.$touch()
            },
            typeChanged(){
                this.$v.groupSession.sessionType.$touch()
            },
            createPressed(){
                console.log(this.groupSession)
                this.$v.$touch()
                if(this.$v.$invalid) {
                    return
                }
                if(this.groupSession.sessionType === "private"){
                    this.createPrivateSession()
                }
                if(this.groupSession.sessionType === "community") {
                    this.createCommunitySession()
                }
            },
            createCommunitySession(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                // if(this.groupSession.sessionSubType === "multiRoomLobby"){
                //     this.groupSession.startsAt = this.minDate.toISOString()
                // }
                console.log("The date is", this.groupSession.startsAt)
                axios.jigo.post(`/v2/community/integrations/groupSessions/newGroupSession/${this.communityOwner}/${this.communityName}`, this.groupSession)
                .then((resp) => {
                    if(resp.status === 200 || resp.status === 201) {
                        loader.hide()
                        this.$router.push(`/groupSession/${resp.data}`)
                    } else {
                        loader.hide()
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: createCommunitySession FAILED", err)
                })
            },
            createPrivateSession(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });

                axios.jigo.post(`/v2/groupSessions/private/new`, this.groupSession)
                .then((resp) => {
                    if(resp.status === 200 || resp.status === 201) {
                        loader.hide()
                        this.$router.push(`/groupSession/${resp.data}`)
                    } else {
                        loader.hide()
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: searchCommunities FAILED", err)
                })
            },
            unselectCommunity(){
                this.groupSession.community = null
            },
            communitySelected(community){
                this.groupSession.community = community
                if(this.groupSession.sessionType === "community" && !this.showAdminSubType) {
                    console.log("Single room it is")
                    this.groupSession.sessionSubType = "singleRoom"
                }
            },
            searchCommunitiesFocusOut(){
              this.$v.groupSession.community.$touch()
            },
            searchCommunitiesFocusin(){
                this.$v.groupSession.community.$reset()
                this.searchCommunities()
            },
            searchCommunities: _.debounce(function ()  {
                if(this.communitySearchTerm.trim() === "") {
                    this.showCoLearnerSelection = false
                    return
                }
                axios.jigo.get(`/v2/user/search/mySubscribedCommunities`, {params: {q: this.communitySearchTerm}})
                .then((resp) => {
                    if(resp.status === 200) {
                        this.subscriptionList = resp.data
                        this.showCommunitySelection = true
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    console.error("ERROR: searchCommunities FAILED", err)
                })
            }, 250),
            addParticipant(user){
                console.log("Adding!")
                let index =  _.findIndex(this.groupSession.invitees, (participant) => {
                    return user.JID === participant.user.JID
                });
                if(index === -1){
                    console.log("Yeahh")
                    let participant = {
                        user: user,
                        role: "participant"
                    }
                    this.groupSession.invitees.push(participant)
                }
            },
            removeParticipant(userID){
                console.log("Trying to remove")
                let indexToRemove =  _.findIndex(this.groupSession.invitees, (participant) => {
                    return userID === participant.user.JID
                });
                if(indexToRemove >= 0){
                    this.groupSession.invitees.splice(indexToRemove, 1)
                }
            },
            searchCoLearners: _.debounce(function ()  {
                if(this.coLearnerSearchTerm.trim() === "") {
                    return
                }
                axios.jigo.get(`/v2/user/coLearners/search`, {params: {q: this.coLearnerSearchTerm}})
                .then((resp) => {
                    if(resp.status === 200) {
                        this.coLearnersList = resp.data
                        this.showCoLearnerSelection = true
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    console.error("ERROR: searchCoLearners FAILED", err)
                })
            }, 250)
        }
    }
</script>

<style scoped>

</style>
